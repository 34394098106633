import React, { useEffect, useState } from 'react'
import './help-center.scss'
import { HelpCenterPresenter } from './help-center-presenter'
import { sanityServices } from '../../services/sanity-service'
import { HelpArticleSearchResult, HelpFeaturedArticle, HelpAPIResponse } from '../../lib/types'

const HelpCenterContainer = () => {
  const [searchText, setSearchText] = useState<string>('')
  const [searchResults, setSearchResults] = useState<HelpArticleSearchResult[]>([])
  const [apiResponse, setApiResponse] = useState<HelpAPIResponse>({
    type: '',
    message: '',
    searchString: '',
  })
  const [featuredArticles, setFeaturedArticles] = useState<HelpFeaturedArticle[]>([])

  // Hook for fetching featured articles
  useEffect(() => {
    const fetchFeaturedArticles = async () => {
      const featuredArticles: HelpFeaturedArticle[] = await sanityServices.getFeaturedArticles()
      setFeaturedArticles(featuredArticles)
    }

    fetchFeaturedArticles()
  }, [])

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  // Search handler
  const handleSearch = () => {
    if (searchText.trim() === '') return

    sanityServices
      .searchArticles(searchText)
      .then((res) => {
        if (Array.isArray(res)) {
          setApiResponse({
            type: 'success',
            message: 'Matching Articles found',
            searchString: searchText,
          })
          setSearchResults(res)
        } else if (res?.statusCode === 400) {
          setApiResponse({
            type: 'error',
            message: res?.body?.error?.description,
            searchString: searchText,
          })
          setSearchResults([])
        }
      })
      .catch((err) => {
        setApiResponse({
          type: 'error',
          message: err?.body?.error?.description,
          searchString: searchText,
        })
      })
  }

  return (
    <HelpCenterPresenter
      searchText={searchText}
      handleSearchChange={handleSearchTextChange}
      handleSearch={handleSearch}
      featuredArticles={featuredArticles}
      searchResults={searchResults}
      apiResponse={apiResponse}
    />
  )
}

export { HelpCenterContainer }
