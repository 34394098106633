/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import {
  H2,
  Span,
  P,
  Flex,
  FlexCenter,
  GeneralIcon,
  PrivacyPolicyIcon,
  KYCIcon,
  DividendsIcon,
  InvestingIcon,
  PlatformIcon,
} from '../../lib/components'
import './faq.scss'
import { useNavigate } from 'react-router-dom'
const faqCardLine1 = [
  {
    title: 'General',
    icon: <GeneralIcon />,
    href: '/General',
    subTitle: 'Learn more about Credibila platform here under this category.',
  },
  // {
  //   title: 'Platform',
  //   icon: <PlatformIcon />,
  //   href: '/Platform',
  //   subTitle: 'Learn more about Credibila platform here under this category.',
  // },
  // {
  //   title: 'Privacy Policy',
  //   icon: <PrivacyPolicyIcon />,
  //   href: '/Privacy-policy',
  //   subTitle: 'Learn more about Credibila platform here under this category.',
  // },
  {
    title: 'Investing',
    icon: <InvestingIcon />,
    href: '/Investing',
    subTitle: 'Learn more about Credibila platform here under this category.',
  },
]
// const faqCardLine2 = [{
//     title: 'Investing',
//     icon: <InvestingIcon />,
//     href: '/Investing',
//     subTitle: 'Learn more about Credibila platform here under this category.'
// }, {
//     title: 'Dividends',
//     icon: <DividendsIcon />,
//     href: '/Dividends',
//     subTitle: 'Learn more about Credibila platform here under this category.'
// },
// {
//     title: 'KYC',
//     icon: <KYCIcon/>,
//     subTitle: 'Learn more about Credibila platform here under this category.'
// }]
const FAQ = () => {
  const navigate = useNavigate()
  const handleClick = (title: string) => navigate(`/faq-details/${title}`)
  return (
    <>
      <FlexCenter classes={['mb-40 mt-19']}>
        <H2 text='FAQ' classes={['mb-24']} />
        <P
          classes={['faq-sub-heading']}
          text='Browse through our categories or search for a term that you are interested in.'
        />
      </FlexCenter>
      <Flex variant='row' classes={['mb-32', 'faq-cards-container']}>
        {faqCardLine1 &&
          faqCardLine1.map(({ title, icon, subTitle }) => (
            <div onClick={() => handleClick(title)} key={title} className='faq-card'>
              <Flex variant='column' classes={['faq-card-wrapper']}>
                <div className='faq-card-icon'>{icon}</div>
                <Span variant='bold' classes={['mb-8 faq-card-title']} text={title} />
                <Span classes={['faq-card-subtitle']} text={subTitle} />
              </Flex>
            </div>
          ))}
      </Flex>
      {/* <Flex variant='row' classes={['mb-24','faq-cards-container']}>
                {faqCardLine2 && faqCardLine2.map(({ title, icon, subTitle }) => (
                <div onClick={() => handleClick(title)} key={title} className='faq-card'>
                    <Flex variant='column' classes={['faq-card-wrapper']}>
                        <div className='faq-card-icon'>{icon}</div>
                        <Span variant='bold' classes={['mb-8 faq-card-title']} text={title} />
                        <Span classes={['faq-card-subtitle']} text={subTitle} />
                    </Flex>
                </div>))}
            </Flex> */}
    </>
  )
}

export { FAQ }
