import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { H1, H2, H3, H4, H5, H6, P, Flex, Span, Anchor } from '../../lib/components'
import { getFormattedDate } from '../../lib/utils/helpers'
import { sanityServices } from '../../services/sanity-service'
import { PortableText, PortableTextComponents } from '@portabletext/react'
import { HelpArticleInterface } from '../../lib/types'

const customComponents: Partial<PortableTextComponents> = {
  block: {
    h1: ({ children }) => <H1 text={children as string} />,
    h2: ({ children }) => <H2 text={children as string} />,
    h3: ({ children }) => <H3 text={children as string} />,
    h4: ({ children }) => <H4 text={children as string} />,
    h5: ({ children }) => <H5 text={children as string} />,
    h6: ({ children }) => <H6 text={children as string} />,
    normal: ({ children }) => {
      // Checking for new/empty line
      if (
        children &&
        Array.isArray(children) &&
        (children?.length === 0 ||
          children.every((child) => typeof child === 'string' && child.trim() === ''))
      ) {
        return <br />
      }
      return <P text={children as string} />
    },
  },
  marks: {
    link: ({ children, value }) => {
      return (
        <Anchor
          variant='basic-anchor'
          text={children as string}
          href={value.href}
          target='_blank'
        />
      )
    },
  },
  list: {
    bullet: ({ children }) => <ul className='ml-16'>{children}</ul>,
    number: ({ children }) => <ol className='ml-16'>{children}</ol>,
  },
  listItem: ({ children }) => (
    <li>
      <P text={children as string} classes={['ml-4']} />
    </li>
  ),
}

const HelpCenterArticle = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { helpItemSlug = '' } = params
  const [helpItem, setHelpItem] = useState<HelpArticleInterface>({
    title: '',
    content: [],
    createdAt: new Date(),
    slug: '',
  })

  useEffect(() => {
    const fetchHelpItemById = async () => {
      const searchResults: any = await sanityServices.getArticleById(helpItemSlug)
      if (Array.isArray(searchResults) && searchResults.length > 0) {
        setHelpItem(searchResults[0])
      } else navigate('/help')
    }
    if (helpItemSlug !== 'undefined') fetchHelpItemById()
    else navigate('/')
  }, [helpItemSlug])

  return (
    <div className='help-center-article-main-layout'>
      <Flex classes={['breadcrumb-content-wrapper']}>
        <>
          <Flex classes={['breadcrumb-navigation']}>
            <div className='pointer' onClick={() => navigate('/home')}>
              <Span text='Home' />
            </div>
            <Span classes={['breadcrumbs-arrow']} text='>' />
            <div className='pointer' onClick={() => navigate('/help')}>
              <Span text='Help Center' />
            </div>
            <Span classes={['breadcrumbs-arrow']} text='>' />
            <div>
              <Span
                classes={['breadcrumb-selected-tab']}
                text={helpItem?.title ? helpItem.title : ''}
              />
            </div>
          </Flex>
        </>
      </Flex>

      <H3 text={helpItem?.title} classes={['help-center-article-main-layout__heading  mt-40']} />
      <P
        text={getFormattedDate(helpItem?.createdAt, 'DD/MM/YYYY')}
        classes={['left', 'mt-16', 'default-gray']}
      />
      <div className='help-center-article-main-layout__content mt-16'>
        <PortableText value={helpItem?.content} components={customComponents} />
      </div>
    </div>
  )
}

export { HelpCenterArticle }
