/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useRef, useEffect } from 'react'
import './home.scss'
import { HomePresenter } from './home-presenter'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setPreviousUrl } from '../../lib/utils/helpers'
import { useNavigate } from 'react-router-dom'
import { showBanner } from '../../global-state/banner-slice'
import { projectServices, homeServices } from '../../services'
import { SearchParams, homeDetails } from '../../lib/types'
import { EVENTS } from '../../lib/utils/constants'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
const HomeContainer = () => {
  const [expand, setExpand] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [projects, setProjects] = useState([])
  const scrollRef = useRef<null | HTMLDivElement>(null)
  const { authToken, isEmailVerified, acceptedNDA, userId } = useSelector(
    (state: RootState) => state.investor,
  )
  const searchParams: SearchParams = {
    sortBy: 'CREATED_AT_DESC',
    offset: '0',
    limit: '3',
  }
  const [homeData, setHomeData] = useState<homeDetails>({
    keyInvestorsImg1: '',
    keyInvestorsImg2: '',
    keyInvestorsImg3: '',
    keyInvestorsImg4: '',
    keyInvestorsImg5: '',
    keyInvestorsName1: '',
    keyInvestorsName2: '',
    keyInvestorsName3: '',
    keyInvestorsName4: '',
    keyInvestorsName5: '',
  })
  useEffect(() => {
    const fetchAllProjects = async () => {
      // const data: any = await projectServices.getSearchProject(searchParams, {
      //   Authorization: authToken ? authToken : ''
      // });
      const data: any = await projectServices.getSearchOpportunities(searchParams, {
        Authorization: authToken ? authToken : '',
      })
      if (data.status === 200) {
        setProjects(data.opportunities || [])
      }
    }
    fetchAllProjects()

    const fetchHomePageDetails = async () => {
      const data: any = await homeServices.getHomeRequest()
      if (data.status === 200) {
        setHomeData(data.data || {})
      }
    }
    // Capture Posthog event
    captureEvent(EVENTS.user_visited_credibila, {})

    fetchHomePageDetails()
  }, [])

  const handleButtonClick = () => {
    setExpand(true)
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }
  const handleProjectClick = (projectSlug: string, id: number) => {
    if (!!authToken && isEmailVerified) {
      if (!acceptedNDA) {
        setPreviousUrl(`/opportunity/${projectSlug}/${id}`)
      } else setPreviousUrl('')
      navigate(`/opportunity/${projectSlug}/${id}`)
    } else if (!!authToken && !isEmailVerified) {
      dispatch(
        showBanner({
          text: 'Please verify your email address to view the opportunity details.',
          variant: 'warning-banner',
        }),
      )
    } else {
      navigate('/login')
    }
  }
  return (
    <HomePresenter
      handleButtonClick={handleButtonClick}
      expand={expand}
      ref={scrollRef}
      handleProjectClick={handleProjectClick}
      projects={projects}
      homeData={homeData}
    />
  )
}

export { HomeContainer }
