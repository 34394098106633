/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, useEffect, useState } from 'react'
import {
  Span,
  Flex,
  FlexCenter,
  GeneralIcon,
  PrivacyPolicyIcon,
  KYCIcon,
  DividendsIcon,
  InvestingIcon,
  PlatformIcon,
  H1,
  ExpandMoreIcon,
  Anchor,
} from '../../lib/components'
import './faq.scss'
import { useParams, useNavigate } from 'react-router-dom'

type faqKey = {
  title: string
  subTitle: string
  icon: ReactNode
  details: { question: string; answer?: string; content?: React.ReactNode }[]
}
interface faqType {
  [key: string]: {
    title: string
    subTitle: string
    icon: ReactNode
    details: { question: string; answer?: string; content?: React.ReactNode }[]
  }
}
interface faqAccordionProps {
  question: string
  answer?: string
  content?: React.ReactNode
  isActive: boolean
  setIsActive: (val: boolean) => void
}
const faqs: faqType = {
  General: {
    title: 'General',
    subTitle: 'Learn more about Credibila platform here under this category.',
    details: [
      {
        question: 'How can I contact the Credibila Team?',
        answer:
          'You may contact the team in one of the two ways: By emailing your relationship manager or by emailing us at support@credibila.com',
      },
      {
        question: 'Who can invest through Credibila?',
        answer:
          'To invest with Credibila, clients need to qualify as accredited clients. This means completing the KYC registration process. Once this is done, our team will inform clients about their eligibility. Our KYC process is mostly automated, aiming to finish within 4 business hours after application submission. If additional information is needed, it might take a bit longer, but we will keep you informed every step of the way',
      },
      {
        question: 'What documents do I have to provide to complete my KYC?',
        content: (
          <>
            FOR AN INDIVIDUAL, we accept the following documents:
            <ul>
              <li>ID card/ current valid Passport/ Visa</li>
              <li>
                Proof of address within 3 months of the issue (utility bill or bank/credit
                statement, or equivalent document)
              </li>
              <li>Original Bank reference Letter, issued within 3 months</li>
              <li>Duly signed and dated CV, showing all qualifications and employment history</li>
              <li>Professional Reference</li>
              <li>Salary confirmation, if any</li>
              <li>Police clearance, if applicable</li>
              <li>Source of Funds/ Source of Wealth</li>
              <li>PEP status confirmation</li>
              <li>Tax status confirmation</li>
            </ul>
            Please note that the above is not an exhaustive list and further documents may be
            requested additionally.
          </>
        ),
      },
      {
        question: 'Is my confidential information safe with Credibila?',
        content: (
          <>
            Maintaining the integrity and security of our Client&apos;s data is a priority for
            Credibila. We follow industry standards and address security in three major components:
            physical security, corporate IT security and production security. Learn More by
            reviewing our
            <Anchor
              variant='basic-anchor'
              classes={['ml-4', 'accordion-anchor']}
              href='/privacy_policy'
              text='Privacy Policy'
            />
            .
          </>
        ),
      },
      {
        question:
          'How long should I expect the verification process to be after I submit the requested documents?',
        answer: 'You can expect the verification process to be completed within 4 business hours.',
      },
      {
        question: 'Why does Credibila need to verify my identity?',
        answer:
          'This requirement is in line with the provisions as set out by the Financial Services Commission ("FCS"), Mauritius in its FSC AML/CFT Handbook and other laws and regulations prevailing in Mauritius which the Company should adhere to.',
      },
      {
        question: 'I forgot my password. What do I do?',
        answer:
          'You can reset your password by clicking “Forgot Password”, which is located on the Login page below “Password.” Once you click “Forgot Password,” you will be asked to enter your email. After you enter your email and click “Submit,” we will send you further instructions.\
          Once the email arrives, please open it and click on the “Reset your password” link provided. You will then be prompted to enter a new password and confirm that password.\
          After you do so, you will be logged in and redirected to the main home page. Once logged in, you will be able to view the website as before!',
      },
      // {
      //   question: 'How do I transfer funds to my account?',
      //   answer:
      //     'Once you open your Investment account, you can transfer funds from your bank. The name on the account you are transferring funds from should match the name on your Investment Account.',
      // },
      {
        question: 'Why is my Investment Account pending?',
        answer:
          'If a red error shows in your Investment Account, please see if you can update your information within your account. If no error shows, or your account requires manual review, please reach out to support@credibila.com',
      },
      {
        question: 'How do I deactivate my account?',
        answer: 'To deactivate your account, please contact your relationship manager.',
      },
    ],
    icon: <GeneralIcon />,
  },
  Investing: {
    title: 'Investing',
    subTitle: 'Learn more about Credibila platform here under this category.',
    details: [
      {
        question: 'How much can I Invest?',
        answer:
          'Each accredited Client , after the completion of the KYC process, will be allotted an Investment Limited which will be dependent on the  accredited Client ’s net worth or source of funds.',
      },
      {
        question: 'What will be the return on my investment be?',
        answer: 'Returns vary per investment opportunity.',
      },
      {
        question: 'Can I cancel my investment at any time ?',
        answer:
          'You can withdraw from an investment at anytime prior to transferring the funds towards your investment. Post transfer of funds, you may request our team or your relationship manager. Once your investment is complete, you may still withdraw from the investment by opting for redemption. Please check the Opportunity page for the terms and conditions that may apply for each Opportunity.',
      },
      {
        question: 'Where can I find more financial information about the opportunity?',
        content: (
          <>
            For each Opportunity, the Credibila Team will attach a complete profile along with the
            link to the Company&apos;s website
          </>
        ),
      },
      {
        question: 'Where can I find the wire details?',
        answer:
          'Once you have picked the opportunity you would like to invest in, you will subscribe to the offer and submit your payment directly to the Bank Account of Credibila. The Bank Account details of Credibila will be shown in a subsequent page after the subscription to the offer. You can also find the instructions to complete your wire transfer by logging into your Credibila account, clicking your name in the top right corner and selecting “View Current” under “My Investments”. You can now simply select the “View Investment” button next to the appropriate investment, and you will be able to view all of the required wire instructions.',
      },
      {
        question: 'When and how will I get confirmation on my investment?',
        answer:
          'Once Credibila receives your funds, you will receive a confirmation email from Credibila with the details about your investment, along with a copy of the terms outlining your investment details.',
      },
    ],
    icon: <InvestingIcon />,
  },
  // Platform: {
  //   title: 'Platform',
  //   subTitle: 'Learn more about Credibila platform here under this category.',
  //   details: [
  //     {
  //       question: 'The Platform type question goes here?',
  //       content:
  //         'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt',
  //     },
  //   ],
  //   icon: <PlatformIcon />,
  // },
  // 'Privacy Policy': {
  //   title: 'Privacy Policy',
  //   subTitle: 'Learn more about Credibila platform here under this category.',
  //   details: [
  //     {
  //       question: 'The Privacy Policy type question goes here?',
  //       content:
  //         'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt',
  //     },
  //   ],
  //   icon: <PrivacyPolicyIcon />,
  // },
  // Dividends: {
  //   title: 'Dividends',
  //   subTitle: 'Learn more about Credibila platform here under this category.',
  //   details: [
  //     {
  //       question: 'The Dividends type question goes here?',
  //       content:
  //         'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt',
  //     },
  //   ],
  //   icon: <DividendsIcon />,
  // },
  // KYC: {
  //   title: 'KYC',
  //   subTitle: 'Learn more about Credibila platform here under this category.',
  //   details: [
  //     {
  //       question: 'The KYC type question goes here?',
  //       content:
  //         'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sintAmet minim mollit non deserunt',
  //     },
  //   ],
  //   icon: <KYCIcon />,
  // },
}

const Accordion = ({ question, answer, content, isActive, setIsActive }: faqAccordionProps) => (
  <div className='accordion'>
    <div className='accordion-item'>
      <div className='accordion-title' onClick={() => setIsActive(!isActive)}>
        <div>{question}</div>
        <div className='accordion-icon'>
          <ExpandMoreIcon classes={isActive ? ['expand-more-icon'] : []} />
        </div>
      </div>
      {isActive && <div className='accordion-content'>{content ?? answer}</div>}
    </div>
  </div>
)
const FAQdetails = () => {
  const [selected, setSelected] = useState<faqKey | null>(null)
  const [isActive, setIsActive] = useState(false)
  const [activeIndex, setIsActiveIndex] = useState<number | null>(null)
  const navigate = useNavigate()
  const params = useParams()
  const { title } = params
  useEffect(() => {
    if (title) {
      setSelected(faqs[title])
    }
  }, [])
  const handleClick = (faq: faqKey) => {
    navigate(`/faq-details/${faq.title}`)
    setSelected(faq)
  }
  return (
    <>
      <Flex classes={['faq-content-wrapper']}>
        <>
          <Flex classes={['faq-navigation']}>
            <div className='pointer' onClick={() => navigate('/home')}>
              <Span text='Home' />
            </div>
            <Span classes={['breadcrumbs-arrow']} text='>' />
            <div className='pointer' onClick={() => navigate('/faq')}>
              <Span text='FAQ' />
            </div>
            <Span classes={['breadcrumbs-arrow']} text='>' />
            <div>
              <Span classes={['faq-selected-tab']} text={title ? title : ''} />
            </div>
          </Flex>
          <FlexCenter classes={['faq-details-heading']}>
            <H1 text='FAQ' />
          </FlexCenter>
        </>
      </Flex>
      <Flex variant='space-between' classes={['faq-container', 'mt-72', 'mb-82']}>
        <Flex variant='column' classes={['faq-side-navigation']}>
          <>
            {Object.keys(faqs).map(
              (key: string) =>
                key && (
                  <div
                    key={key}
                    className={
                      faqs[key].title === selected?.title
                        ? 'faq-navigation-list faq-selected-container'
                        : 'faq-navigation-list'
                    }
                    onClick={() => handleClick(faqs[key])}
                  >
                    <Flex variant='row'>
                      <div className='faq-details-icon'>{faqs[key].icon as ReactNode}</div>
                      <Span
                        classes={['faq-list-item', 'pointer']}
                        text={faqs[key].title as string}
                      />
                    </Flex>
                  </div>
                ),
            )}
          </>
        </Flex>
        <Flex variant='column' classes={['faq-accordion', 'ml-59']}>
          {selected?.details &&
            selected?.details.map(
              (
                {
                  question,
                  answer,
                  content,
                }: { question: string; answer?: string; content?: React.ReactNode },
                index,
              ) => (
                <Accordion
                  key={index}
                  question={question}
                  answer={answer}
                  content={content}
                  isActive={activeIndex === index ? isActive : false}
                  setIsActive={(val: boolean) => {
                    setIsActiveIndex(index)
                    return setIsActive(val)
                  }}
                />
              ),
            )}
        </Flex>
      </Flex>
    </>
  )
}

export { FAQdetails }
