/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '../../store'
import { InvestPresenter } from './invest-presenter'
import { projectServices, kycServices, investServices, documentServices } from '../../services'
import { ProjectCardProps } from '../../lib/types'
import { isValidDocType, isValidFileSize } from '../../lib/utils/helpers'
import { showBanner } from '../../global-state/banner-slice'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { EVENTS } from '../../lib/utils/constants'
import './invest.scss'

const InvestContainer = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const { projectId, projectSlug } = params
  const { authToken, userId } = useSelector((state: RootState) => state.investor)
  const { isConfirm } = useSelector((state: RootState) => state.confirmModel)
  const [project, setProject] = useState<ProjectCardProps>()
  const [userInvestmentDetail, setUserInvestmentDetail] = useState<any>()
  const [investmentData, setInvestmentData] = useState<any>()
  const [currentStep, setCurrentStep] = useState(1)
  const [completedStep, setCompletedStep] = useState(0)
  const [investmentValue, setInvestmentValue] = useState<number>(0)
  const [agreementLink, setAgreementLink] = useState<string>('')
  const [isApiError, setIsApiError] = useState<boolean>(false)
  const [showContactUsModal, setContactUsModal] = useState(false)

  useEffect(() => {
    const getInvestment = async () => {
      // const response: any = await InvestServices.getInvestmentByProjectId(
      //   projectId,
      //   'PENDING',
      //   {
      //     Authorization: authToken ? authToken : '',
      //   }
      // );
      const response: any = await investServices.getInvestmentByOpportunityId(
        projectId,
        'PENDING',
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response && response.data && response.status === 200) {
        setInvestmentData(response.data)
        setInvestmentValue(response.data?.amount / 100)

        if (response.data.metadata) {
          setCompletedStep(parseInt(response.data?.metadata?.completedSteps))
          setCurrentStep(parseInt(response.data?.metadata?.completedSteps) + 1)
        }
      }
    }
    if (projectId !== 'undefined' && projectSlug !== 'undefined') {
      fetchProjectById()
      getUserInvestments()
      getInvestment()
    } else navigate('/')
  }, [])

  useEffect(() => {
    getInvestment()
  }, [currentStep])

  useEffect(() => {
    const getUserInvestmentLatest = async () => {
      const response: any = await investServices.getInvestmentLatest_v1(
        { opportunityId: projectId ? parseInt(projectId) : 0 },
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response && response.data && response.status === 200) {
        if (response.data.status === 'INITIATED') navigate(-1)
      }
    }
    const getKYC = async () => {
      // const response: any = await kycServices.getKYC(userId.toString(), {
      //   Authorization: authToken ? authToken : '',
      // });
      const response: any = await kycServices.getKYC_v1(userId.toString(), {
        Authorization: authToken ? authToken : '',
      })
      if (!!response && response.data && response.status === 200) {
        if (response.data.status !== 'APPROVED') {
          dispatch(
            showBanner({
              text: 'Please complete the KYC for investing in the opportunities.',
              variant: 'error-banner',
            }),
          )
          navigate('/profile/kyc')
        }
      }
    }
    if (authToken) {
      getKYC()
      getUserInvestmentLatest()
    }
  }, [])

  useEffect(() => {
    const handleAgreement = async () => {
      const payload = {
        investmentId: investmentData.id,
        userId: investmentData.investor_id,
        projectId: investmentData.opportunity_id,
        metadata: { ...investmentData.metadata, completedSteps: 2, agreement: agreementLink },
      }
      const data = await investServices.updateOpportunityInvestment(payload, {
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        setInvestmentData(data.data)
        setInvestmentValue(data.data?.amount / 100)
        dispatch(
          showBanner({
            text: 'Agreement submitted successfully',
            variant: 'success-banner',
          }),
        )
        setIsApiError(false)
        setCurrentStep((prev) => prev + 1)
      } else {
        dispatch(
          showBanner({
            text: (data.data && data.data.message) || 'Something went wrong! Please try again.',
            variant: 'error-banner',
          }),
        )
        setIsApiError(true)
      }
    }
    if (isConfirm) handleAgreement()
  }, [isConfirm])

  const getInvestment = async () => {
    const response: any = await investServices.getInvestmentByOpportunityId(projectId, 'PENDING', {
      Authorization: authToken ? authToken : '',
    })

    if (response && response.data && response.status === 200) {
      setInvestmentData(response.data)
      setInvestmentValue(response.data?.amount / 100)
      setCompletedStep(response.data?.metadata?.completedSteps)
    }
  }

  const getUserInvestments = async () => {
    const response: any = await investServices.getUserInvestmentDetail_v1({
      Authorization: authToken ? authToken : '',
    })
    if (response && response.data && response.status === 200) {
      setUserInvestmentDetail(response.data)
    }
  }

  const fetchProjectById = async () => {
    const data: any = await projectServices.getOpportunityById(projectId as string, {
      Authorization: authToken ? authToken : '',
    })
    if (data && data.data && data.status === 200) {
      setProject(data.data)
      setInvestmentValue((data.data && data.data.ticketSize / 100) || 0)
    }
  }

  const postInvestmentAmount = async (payload: any) => {
    const response = await investServices.postOpportunityInvestment(payload, {
      Authorization: authToken ? authToken : '',
    })
    if (response.status === 200) {
      setIsApiError(false)
      setCurrentStep((prev) => prev + 1)
    } else {
      const message = response.data.message || 'Error saving the investment'
      dispatch(showBanner({ text: message, variant: 'error-banner' }))
      setIsApiError(true)
    }
  }

  const updateInvestmentAmount = async (payload: any) => {
    const data: any = await investServices.updateOpportunityInvestment(payload, {
      Authorization: authToken ? authToken : '',
    })
    if (data && data.data && data.status === 200) {
      setInvestmentData(data.data)
      setInvestmentValue(data.data?.amount / 100)
      setIsApiError(false)
      setCurrentStep((prev) => prev + 1)
    } else {
      const message = data.data.message || 'Error saving the investment'
      dispatch(showBanner({ text: message, variant: 'error-banner' }))
      setIsApiError(true)
    }
  }

  const handleInvestmentAmount = async () => {
    if (!investmentData) {
      const payload = {
        projectId: projectId,
        investmentAmount: investmentValue,
        metadata: { completedSteps: 1 },
      }
      await postInvestmentAmount(payload)
    }
    if (investmentData && investmentData?.amount) {
      const payload = {
        projectId: projectId,
        userId: userId,
        investmentId: investmentData.id,
        investmentAmount: investmentValue,
      }
      await updateInvestmentAmount(payload)
      // captureEvent(EVENTS.INVESTMENT_AMOUNT_ENTERED, { payload })
    }
    await getUserInvestments()
  }
  const backToProjectHandler = () => navigate(`/opportunity/${project?.projectSlug}/${project?.id}`)

  const handleCurrentStep = (val: number) => {
    setCurrentStep(val)
  }

  const handleDisableButton = (): boolean => {
    const canInvestAmount = investmentData?.amount
      ? parseInt(userInvestmentDetail?.canInvest) / 100 + parseInt(investmentData?.amount) / 100
      : parseInt(userInvestmentDetail?.canInvest) / 100
    if (isApiError) return true
    if (
      currentStep === 1 &&
      !!project?.ticketSize &&
      investmentValue >= parseInt(project?.ticketSize) / 100 &&
      investmentValue <=
        Math.min(
          (parseInt(project?.raiseGoal) - parseInt(project?.committedAmount)) / 100,
          canInvestAmount,
        )
    )
      return false
    else if (currentStep === 2 && agreementLink) return false
    else if (
      currentStep === 3 &&
      investmentData &&
      investmentData.paymentCopy &&
      investmentData.paymentCopy.length > 0
    )
      return false

    return true
  }

  const handleFileUpload = async (fileData: File) => {
    const isValidImage = isValidDocType(fileData)
    const isValidSize = isValidFileSize(fileData, 'doc')
    if (isValidImage && isValidSize) {
      const data = await projectServices.postUploadFiles(fileData, {
        Authorization: authToken ? authToken : '',
      })
      if (data) return data
    } else {
      dispatch(
        showBanner({
          text: 'File size or type is incorrect for field',
          variant: 'error-banner',
        }),
      )
    }
  }

  const handleUploadAgreement = (agreement: string) => {
    setAgreementLink(agreement)
    captureEvent(EVENTS.agreement_signed, {})
  }

  const handleAgreement = async () => {
    const payload = {
      investmentId: investmentData.id,
      userId: investmentData.investor_id,
      projectId: investmentData.opportunity_id,
      metadata: { ...investmentData.metadata, completedSteps: 2, agreement: agreementLink },
    }
    const data = await investServices.updateOpportunityInvestment(payload, {
      Authorization: authToken ? authToken : '',
    })
    if (data && data.data && data.status === 200) {
      setInvestmentData(data.data)
      setInvestmentValue(data.data?.amount / 100)
      dispatch(
        showBanner({
          text: 'Agreement submitted successfully',
          variant: 'success-banner',
        }),
      )
      setIsApiError(false)
      setCurrentStep((prev) => prev + 1)
    } else {
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
      setIsApiError(true)
    }
  }

  const handleCompletePayment = async (swiftPaymentCopy: string) => {
    if (!swiftPaymentCopy) return
    const payload = {
      userId: investmentData.investor_id,
      projectId: investmentData.opportunity_id,
      investmentId: investmentData.id,
      status: 'INITIATED',
      metadata: {
        ...investmentData.metadata,
        completedSteps: 3,
        paymentCopy: swiftPaymentCopy,
      },
    }
    const data = await investServices.updateOpportunityInvestment(payload, {
      Authorization: authToken ? authToken : '',
    })
    if (data && data.data && data.status === 200) {
      setInvestmentData(data.data)
      setInvestmentValue(data.data?.amount / 100)
      dispatch(
        showBanner({
          text: 'Payment details submitted successfully',
          variant: 'success-banner',
        }),
      )
      setIsApiError(false)
      navigateToPaymentSuccessScreen()
      captureEvent(EVENTS.investment_application_submitted, { data })
    } else setIsApiError(true)
  }

  const navigateToPaymentSuccessScreen = () => {
    navigate(`/payment/${projectSlug}/${projectId}/submitted-successful`)
  }

  const handleShowContactUsModal = () => setContactUsModal(!showContactUsModal)

  async function getInvestmentDocument(documentId: string) {
    const response = await investServices.getInvestmentDocument(documentId, {
      Authorization: authToken ? authToken : '',
    })

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  async function getSignedDocument() {
    const response = await investServices.getInvestorSignedAgreement({
      Authorization: authToken ? authToken : '',
    })

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  async function getDocumentAccessToken() {
    const response = await documentServices.getAccessToken({
      Authorization: authToken ? authToken : '',
    })

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  async function getDocumentSigningLink(accessToken: string) {
    const response = await documentServices.getSigningLink(accessToken, {
      Authorization: authToken ? authToken : '',
    })

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  async function getDocumentDownloadLink(accessToken: string, documentId: string) {
    const response = await documentServices.getDownloadLink(accessToken, documentId, {
      Authorization: authToken ? authToken : '',
    })

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  return (
    <InvestPresenter
      backToProjectHandler={backToProjectHandler}
      project={project}
      currentStep={currentStep}
      handleCurrentStep={handleCurrentStep}
      userInvestmentDetail={userInvestmentDetail}
      investmentValue={investmentValue}
      setInvestmentValue={setInvestmentValue}
      investmentData={investmentData}
      handleDisableButton={handleDisableButton}
      handleInvestmentAmount={handleInvestmentAmount}
      handleFileUpload={handleFileUpload}
      handleCompletePayment={handleCompletePayment}
      navigateToPaymentSuccessScreen={navigateToPaymentSuccessScreen}
      handleUploadAgreement={handleUploadAgreement}
      handleAgreement={handleAgreement}
      completedStep={completedStep}
      setIsApiError={setIsApiError}
      agreementLink={agreementLink}
      showContactUsModal={showContactUsModal}
      handleShowContactUsModal={handleShowContactUsModal}
      setAgreement={setAgreementLink}
      getInvestmentDocument={getInvestmentDocument}
      getSignedDocument={getSignedDocument}
      getDocumentAccessToken={getDocumentAccessToken}
      getDocumentSigningLink={getDocumentSigningLink}
      getDocumentDownloadLink={getDocumentDownloadLink}
    />
  )
}

export { InvestContainer }
